.root {
  @apply grid;
  @apply mx-auto;
  @apply relative;
  grid-template-columns: repeat(var(--grid-columns), minmax(0, 1fr));
  width: var(--container-width);
  column-gap: var(--gutter-width);
}

.root.rows {
  @apply auto-rows-auto;
  @apply gap-y-6;
  @apply lg:gap-y-11;
}

.root.fullHeight {
  @apply min-h-full;
}

.root.fullWidthMobile {
  @apply w-full;
}

@screen md {
  .root.fullWidthMobile {
    width: var(--container-width);
  }
}
